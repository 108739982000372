// Components
import Panel from "../../Components/Panel/Panel";
import BackgroundBanner from "../../Components/Banner/BackgroundBanner";

// Locales
import { useTranslation } from "react-i18next";

export default function Projects() {
	const { t } = useTranslation("", {
		keyPrefix: "projects",
	});

	//data array
	const panels = [
		t("learningProjectsData", { returnObjects: true }),
		t("feupmayhemData", { returnObjects: true }),
		t("mootinyData", { returnObjects: true }),
		t("websitepanelData", { returnObjects: true }),
	];

	return (
		<>
			<div>
				<BackgroundBanner
					{...t("projectsbannerdata", { returnObjects: true })}
				/>
				<div className="d-flex flex-column align-items-center container py-5">
					{panels.map((panel, i) => (
						<Panel key={i} {...panel} />
					))}
				</div>
			</div>
		</>
	);
}
