// Components
import Panel from "../../Components/Panel/Panel";
import BackgroundBanner from "../../Components/Banner/BackgroundBanner";
import Card from "../../Components/Card/Card";

// Locales
import { useTranslation } from "react-i18next";

export default function Home() {
	const { t } = useTranslation("", {
		keyPrefix: "home",
	});

	return (
		<div>
			<BackgroundBanner {...t("bannerData", { returnObjects: true })} />
			<div className="d-flex flex-column align-items-center container py-5">
				<div className="row">
					<h2 className="cards-title text-white fw-bold text-uppercase text-center mb-5">
						{t("cardsSectionTitle")}
					</h2>
				</div>
				<div className="row px-0 px-xl-2 mx-0 mx-xl-2">
					{t("cards", { returnObjects: true }).map((card, i) => (
						<Card key={`card-${i}`} data={card} />
					))}
				</div>
			</div>

			<div className="bg-color-2">
				<div className="d-flex flex-column align-items-center container py-5">
					{t("panels", { returnObjects: true }).map((panel, i) => (
						<Panel key={`panel-${i}`} {...panel} />
					))}
				</div>
			</div>
		</div>
	);
}
