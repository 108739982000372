// Libraries
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// Locales
import { useTranslation } from "react-i18next";

// Assets
import logo from "../../assets/images/ncgm_logo_transparent.png";

// Styles
import "./Navbar.css";

export default function Navbar() {
	const [scrollPos, setScrollPos] = useState(window.scrollY);
	const [collapseNavbar, setCollapseNavbar] = useState(false);
	const [hamburguerOpen, setHamburguerOpen] = useState(false);

	const { t } = useTranslation("", {
		keyPrefix: "navbar",
	});

	// get current page (last token in pathname string)
	const currLocation =
		useLocation().pathname.split("/")[
			window.location.pathname.split("/").length - 1
		];

	// keys in the locale dictionary, to add as links in the navbar
	const linkKeys = ["about", "team", "events", "projects", "contacts"];

	const lgBreakpoint = 992; //px

	// Add event listener to scroll and resize event, and check whether to collapse navbar after the first render
	useEffect(() => {
		setCollapseNavbar(window.innerWidth < lgBreakpoint);
		window.addEventListener("scroll", handleScroll);
		window.addEventListener("resize", handleResize);
	}, []);

	const handleScroll = () => {
		setScrollPos(window.scrollY);
	};

	const handleResize = () => {
		const smallScreen = window.innerWidth < lgBreakpoint;
		setCollapseNavbar(smallScreen);
		// close hamburguer if the breakpoint is lg or higher and the hamburguer was open
		if (hamburguerOpen && !smallScreen) {
			setHamburguerOpen(false);
		}
	};

	const navItems = (
		<ul className="navbar-nav me-3">
			{linkKeys.map((key, i) => (
				<li
					key={`nav-item-${i}`}
					className={
						"nav-item nowrap mx-4 p-2" +
						(currLocation === key ? " nav-item-selected" : "")
					}
				>
					<Link to={`/${key}`}>{t(key)}</Link>
				</li>
			))}
		</ul>
	);

	return (
		<nav
			className={
				"navbar navbar-expand-lg sticky-top w-100" +
				(scrollPos !== 0 ? " navbar-scrolled" : "")
			}
		>
			<div className="container-fluid px-5">
				<div className="nav-item navbar-logo ms-3">
					<Link to="/">
						<img src={logo} alt="logo"></img>
					</Link>
				</div>
				{collapseNavbar ? (
					<>
						<button
							className={
								"navbar-toggler navbar-hamburguer" +
								(hamburguerOpen ? " navbar-hamburguer-active" : "")
							}
							type="button"
							aria-expanded="false"
							aria-label="Toggle navigation"
							onClick={() => setHamburguerOpen(!hamburguerOpen)}
						>
							<span className="navbar-toggler-icon"></span>
						</button>
						{hamburguerOpen && (
							// absolute relative to sticky navbar
							<div className="bg-color-1 rounded position-absolute floating-navitems mx-5">
								{navItems}
							</div>
						)}
					</>
				) : (
					navItems
				)}
			</div>
		</nav>
	);
}
