// Components
import Banner from "../../Components/Banner/Banner";
import Panel from "../../Components/Panel/Panel";
import Card from "../../Components/Card/Card";

// Locales
import { useTranslation } from "react-i18next";

export default function About() {
	const { t } = useTranslation("", {
		keyPrefix: "about",
	});

	return (
		<div>
			<Banner {...t("bannerData", { returnObjects: true })} />
			<div className="d-flex flex-column align-items-center container py-5">
				<Panel {...t("panelData", { returnObjects: true })} />

				<div className="row">
					<h2 className="cards-title text-white fw-bold text-uppercase text-center mb-5">
						{t("evTitle")}
					</h2>
				</div>
				<div className="row px-0 px-xl-2 mx-0 mx-xl-2">
					{t("cardsData", { returnObjects: true }).map((card, i) => (
						<Card key={`card-${i}`} data={card} />
					))}
				</div>
			</div>
		</div>
	);
}
