// Libraries
import React, { useState } from "react";

// Locales
import { useTranslation } from "react-i18next";

// Assets
import contacts_image from "../../assets/images/page-bg/bg-ncgm-contact.svg";

// Styles
import "./Contacts.css";

export default function Contacts() {
	const { t } = useTranslation("", {
		keyPrefix: "contact",
	});

	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		message: "",
	});

	const handleChange = (event) => {
		const { id, value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[id]: value,
		}));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
	};

	return (
		<div>
			<div className="container-fluid vh-100 position-relative overflow-hidden">
				<div className="h-100 row full-page-minus-nav-footer-height d-flex flex-column-xs flex-row-lg justify-content-center align-items-center position-relative">
					<div className="contacts-page-margin mt-lg-0 full-page-padding-xs full-page-padding-left-lg pe-lg-0 pt-5 py-lg-0 col-lg-6 col-12 z-5">
						<form onSubmit={handleSubmit}>
							<h1 className="banner-title">{t("title")}</h1>
							<p className="banner-subtitle">{t("subtitle")}</p>
							<div className="row my-4">
								<label htmlFor="firstName" className="col-6 col">
									<input
										className="input-field"
										id="firstName"
										type="text"
										placeholder={t("placeholder.name")}
										value={formData.firstName}
										onChange={handleChange}
										required
									/>
								</label>
								<label htmlFor="lastName" className="col-6 col">
									<input
										className="input-field"
										id="lastName"
										type="text"
										placeholder={t("placeholder.surname")}
										value={formData.lastName}
										onChange={handleChange}
										required
									/>
								</label>
							</div>
							<div className="row my-4">
								<label className="col-lg-12 col-sm-12 col-md-12 col-12 col">
									<input
										className="input-field"
										id="email"
										type="text"
										placeholder={t("placeholder.email")}
										value={formData.email}
										onChange={handleChange}
										required
									/>
								</label>
							</div>
							<div className="row my-4">
								<label className="col-lg-12 col-sm-12 col-md-12 col-12 col">
									<textarea
										className="input-field"
										id="message"
										placeholder={t("placeholder.message")}
										value={formData.message}
										onChange={handleChange}
										required
									/>
								</label>
							</div>

							<button
								className="btn accent-filled-button my-2 px-3 py-3"
								type="submit"
							>
								{t("buttonText")}
							</button>
						</form>
					</div>
					<div className="banner-image-container d-flex col-lg-6 justify-content-center align-items-center">
						<img
							className="banner-image"
							src={contacts_image}
							alt="contacts_image"
						></img>
					</div>
				</div>
			</div>
		</div>
	);
}
