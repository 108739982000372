// Assets
// Home Page Assets
import bannerimg from "../../assets/images/page-bg/bg-ncgm-home.jpg";
import gamedevmeetportoimg from "../../assets/images/events/gdm-panel.png"; // same as gdm_panel
import feupmayhemimg from "../../assets/images/projects/feup-mayhem.png"; // home and projects
import cgraphics from "../../assets/images/cards/cgraphics.svg"; // home and about
import gamedev from "../../assets/images/cards/gamedev.svg"; // home and about
import interaction from "../../assets/images/cards/interaction.svg"; // home and about
import multimedia from "../../assets/images/cards/multimedia.svg"; // home and about
// About Page Assets
import about_bg from "../../assets/images/page-bg/bg-ncgm-about.svg";
import feup_bg from "../../assets/images/page-bg/feup-banner.fcac53a9.svg";
// Events Page Assets
import event_bg from "../../assets/images/page-bg/bg-ncgm-events.svg";
import gdm_panel from "../../assets/images/events/gdm-panel.png";
import ggj_panel from "../../assets/images/events/globalgamejam-panel.jpg";
import learningProjectsImg from "../../assets/images/projects/learning-projects-photo.jpg";
// Project Page Assets
import projectbannerimg from "../../assets/images/page-bg/bg-ncgm-projects.jpg";
import websiteimg from "../../assets/images/projects/website-NCGM.jpg";
import mootinyimg from "../../assets/images/projects/mootiny.png";
// Team Page Assets
import team_bg from "../../assets/images/page-bg/bg-ncgm-team.svg";
// import vr_img from "../../assets/images/team/anniversary2-vr.svg";
import anniv_fmq from "../../assets/images/team/anniversary2-fbmaq.svg";
// GGJ Page Assets
import ggjbanner from "../../assets/images/events/GGJ_Baner.png";
import ggjlogo from "../../assets/images/events/GGJ_RoundLogo.png";
import ggjlocation from "../../assets/images/events/GGJ_location.png";
import appleicon from "../../assets/images/cards/apple_icon.png";
import soapicon from "../../assets/images/cards/soap_icon.png";
import groupicon from "../../assets/images/cards/group_icon.png";
import sofaicon from "../../assets/images/cards/sofa_icon.png";
// Team Members
import pres_img from "../../assets/images/team/our_team/presidente.png";
import vice_img from "../../assets/images/team/our_team/vice_presidente.png";
import treas_img from "../../assets/images/team/our_team/tesoureiro.png";
import agpres_img from "../../assets/images/team/our_team/AG_presidente.png";
import agsec1_img from "../../assets/images/team/our_team/AG_secretario1.png";
import agsec2_img from "../../assets/images/team/our_team/AG_secretario2.png";
import cfpres_img from "../../assets/images/team/our_team/CF_presidente.png";
import cfsec_img from "../../assets/images/team/our_team/CF_secretario.png";
import cfvwl_img from "../../assets/images/team/our_team/CF_vogal.png";

const LOCALES_EN = {
	navbar: {
		about: "About Us",
		team: "Our Team",
		events: "Events",
		projects: "Projects",
		contacts: "Contacts",
	},
	home: {
		bannerData: {
			title: "WE ARE NCGM",
			img: bannerimg,
			buttons: [
				{
					buttonURL: "/about",
					buttonText: "Get to know us!",
				},
				{
					buttonURL: "https://forms.gle/wtvRo2iEJvNcvEEf9",
					buttonText: "Join us!",
				},
			],
		},
		cardsSectionTitle: "We Are Passionate About",
		cards: [
			{
				img: cgraphics,
				title: "Computer Graphics",
				description:
					"This vast, exciting area of computer science is in constant evolution and is the core of digital photography, film, and video games",
			},
			{
				img: gamedev,
				title: "Game Development",
				description:
					"From the simplest 2D indie platformers to the most complex AAA strategy games, in NCGM's heart resides a deep love for Game Dev",
			},
			{
				img: interaction,
				title: "Interface Design",
				description:
					"Developing a frictionless, aesthetically pleasing, and clean bridge between the user and the device is, an art we seek to learn",
			},
			{
				img: multimedia,
				title: "Multimedia",
				description:
					"At NCGM, we recognize the importance of audiovisual interaction, and the artistic freedom it allows, in any kind of application",
			},
		],
		panels: [
			{
				type: "EVENT",
				title: "GAME DEV MEET @ PORTO",
				description: [
					"Our biggest regular event is the Game Dev Meet @Porto, a monthly gathering of amateur or professional creators, or games and multimedia experiments aficionados.",
					"This event seeks to foment the interaction between creators and admirers, and constitutes a perfect space to share projects, hang out and discuss ideas.",
					"NCGM began organizing this event in May 2017, and we have an average of 50 participants per session!",
					"Admission is free, and no previous registration is needed! From sound to graphic designers, writers to artists, programmers or just curious people, anyone can participate, show off their work, learn, teach, and most importantly of all, have fun!",
				],

				img: [{ img: gamedevmeetportoimg, imgText: "Game Dev Meet at Porto" }],
				imgRight: false,

				hasButton: true,
				refButton: "/events",
				buttonText: "SEE ALL OUR EVENTS",
			},
			{
				type: "PROJECT",
				title: "FEUP MAYHEM",
				description: [
					"A game for two players based on Gun Mayhem.",
					"The overall theme is FEUP, and the characters represent different Engineering degrees of the aforementioned college.",
				],

				img: [{ img: feupmayhemimg, imgText: "FEUP Mayhem" }],
				imgRight: true,

				hasButton: true,
				refButton: "/projects",
				buttonText: "CHECK OUR PROJECTS",
			},
		],
	},
	about: {
		// WE SEEK TO EXCEL
		bannerData: {
			title: "WE SEEK TO EXCEL",
			description:
				"Since its inception, NCGM has sought to promote interdisciplinarity, exploring the synergies, convergences, and possible links between many scientific, technological, humanistic, and artistic areas. To this end, we have developed numerous projects and organised and promoted events within its areas of activity aimed at the student community and any enthusiast in these areas.",
			img: about_bg,
			imgText: "banner test",
			buttons: [],
		},

		// OUR HISTORY

		panelData: {
			type: "Our history",
			title: "Bringing talents together since 2006",
			description: [
				"NCGM is a non-profit organisation founded in 2006 by students interested in Computer Graphics, Game Development and Digital Experiences, and Human-Computer Interaction.",

				"Initially called NeCG, in 2017, it merged with the Multimedia Master's Student Centre to promote the development of projects with multidisciplinary teams and to broaden the impact of both centres on their communities. After this official merger, the centre took on its current name, Computer Graphics and Multimedia Centre or NCGM.",

				"Currently, the centre comprises around 50 students, the majority of whom are students from the Faculty of Engineering at the University of Porto, and the rest belong to other faculties in the Porto area.",

				"If you're interested in our areas of activity, don't hesitate to contact us. We always seek motivated people who share our passion for all computer graphics and multimedia-related topics!",
			],

			img: [{ img: feup_bg, imgText: "Feup Campus" }],
			imgRight: false,
			imgText: "History",

			hasButton: true,
			refButton: "./contact",
			buttonText: "Contact us!",
		},
		// EVENTS AND PROJECTS
		evTitle: "WE ORGANIZE EVENTS AND PROJECTS IN",

		cardsData: [
			{
				img: cgraphics,
				title: "Computer Graphics",
			},
			{
				img: gamedev,
				title: "Game Development",
			},
			{
				img: interaction,
				title: "Interface Design",
			},
			{
				img: multimedia,
				title: "Multimedia",
			},
		],
	},
	events: {
		bannerData: {
			title: "CONNECTING TALENTED PEOPLE",
			description:
				"Our Nucleus organises workshops and lectures to improve its members' and associates' skills and abilities, providing them with the means to develop projects and activities of value to themselves and their community.",
			img: event_bg,
			imgText: "banner text",
			buttons: [
				{
					buttonURL: "/about",
					buttonText: "Get to know us!",
				},
			],
		},

		gdmPanelData: {
			type: "EVENT",
			title: "Game Dev Meet @ Porto",
			description: [
				"Our biggest regular event is the Game Dev Meet @Porto, a monthly gathering of amateur or professional creators, or games and multimedia experiments aficionados.",
				"This event seeks to foment the interaction between creators and admirers, and constitutes a perfect space to share projects, hang out and discuss ideas.",
				"NCGM began organizing this event in May 2017, and we have an average of 50 participants per session!",
				"Admission is free, and no previous registration is needed! From sound to graphic designers, writers to artists, programmers or just curious people, anyone can participate, show off their work, learn, teach, and most importantly of all, have fun!",
				"Join our discord server to stay up to date!",
			],
			img: [{ img: gdm_panel, imgText: "GDM" }],
			imgRight: false,

			hasButton: true,
			refButton: "https://discord.com/invite/db9zYgvpNE",
			buttonText: "Server Link",
		},

		ggjPanelData: {
			type: "EVENT",
			title: "Global Game Jam",
			description: [
				"Take part in the world's biggest game jam! Develop a game from start to finish in 48 hours, alone or in a team. At the beginning of the jam, a theme is given based on which the participants have to create their game!",
				"The NCGM hosts a jam site at FEUP, where teams come together to participate in the competition!",
				"The GGJ usually occurs at the end of January; the precise date can be found on the official Global Game Jam website.",
			],

			img: [{ img: ggj_panel, imgText: "GGJ" }],
			imgRight: true,

			hasButton: true,
			refButton: "https://globalgamejam.org/",
			buttonText: "VISIT THE GGJ WEBSITE!",
		},
	},
	projects: {
		projectsbannerdata: {
			img: projectbannerimg,
			title: "WE GET THINGS DONE",
			description:
				"Our Nucleus hosts workshops and lectures with the intention of improving our members' and associates' capacities and aptitudes, providing them with means to develop projects and activities of value to them, and to the community they belong to.",
		},
		websitepanelData: {
			type: "PROJECT",
			title: "NCGM's WEBSITE",
			description: [
				"The comfy place we're in right now.",
				"Come and sit by the hearth.",
			],

			img: [{ img: websiteimg, imgText: "NCGM's Website" }],
			imgRight: true,

			hasButton: true,
			refButton: "https://github.com/ncgmfeup/website-mern",
			buttonText: "GIT REPOSITORY",
		},
		feupmayhemData: {
			type: "PROJECT",
			title: "FEUP MAYHEM",
			description: [
				"FEUP Mayhem is a two-player 2D platform shooter inspired by Gun Mayhem and Super Smash Bros.",
				"The game takes place during a particularly exhausting semester, in which students fight to defend their Engineering degree.",
				"Players take control of one of 4 available characters, each with a backstory and a special ability based on their respective course, which can turn the course of the battle instantly, when used efficiently!",
				"They must use their abilities, weapons and wits to gain an edge over their opponent and knock them off the map!",
				"Bring pride upon your degree and become the ultimate Engineer!",
			],

			img: [{ img: feupmayhemimg, imgText: "FEUP Mayhem" }],
			imgRight: true,

			hasButton: true,
			refButton: "https://github.com/ncgmfeup/summer-project-2020",
			buttonText: "GIT REPOSITORY",
		},
		mootinyData: {
			type: "PROJECT",
			title: "Mootiny in Barksville",
			description: [
				"Oh no the big bad is destroying the forest! Join Butter Dog (name pending) and save the animals in this epic 2d action platformer. Give your opposition a buttery finish as you uncover the secrets of this unfinished game.",
				"Ideas and mechanics inspired by games like Celeste, Pizza Tower, Warioland, Undertale and OneShot.",
			],

			img: [{ img: mootinyimg, imgText: "Mootiny" }],
			imgRight: false,
			hasButton: false,
		},
		learningProjectsData: {
			type: "PROJECT",
			title: "Learning Projects",
			description: [
				"Our Learning Projects are an excellent opportunity for whoever is interested in the game development field and wants to take their first steps into learning engines and other game development tools widely used in the industry.",
				"During these projects, each team, comprised of 2 to 3 members, has the support of experienced mentors who guide them throughout the process of designing and developing their game, ensuring the team successfully completes this project under two months.",
			],

			img: [{ img: learningProjectsImg, imgText: "Learning Project" }],
			imgRight: false,
		},
	},
	team: {
		bannerData: {
			img: team_bg,
			title: "WE ARE A TEAM OF MAKERS",
			description:
				"We prioritise a practical approach to developing new skills, gaining experience, and learning by doing, resulting not only in our members feeling more engaged but also promoting their creativity and problem-solving skills.",
		},
		teamPanelData: {
			type: "WE ARE",
			title: "60+ MEMBERS",
			description: [
				"With a relatively recent history, dating back to 2006, NCGM has looked to attract and provide a helpful platform to any and all enthusiasts interested in Computer Graphics, Game Dev, and Multimedia.",
				"We seek to foster a strong relationship of cooperation and knowledge-sharing between our members. Among students, or even through our alumni, we are committed to create a better process for young people to adapt to a world that, for many, can be quite challenging (or even completely unknown).",
				"Do you want to be our next member? Sign up now",
			],

			img: [{ img: anniv_fmq, imgText: "event with public audience" }],
			imgRight: false,

			hasButton: true,
			refButton: "./contact",
			buttonText: "JOIN US!",
		},
		ourTeam: "Our Team",
		boardNCGM: [
			{ role: "Vice-President", name: "António Matos", img: vice_img },
			{ role: "President", name: "Ana Ramos", img: pres_img },
			{ role: "Treasurer", name: "Tomás Moreira", img: treas_img },
			{
				role: "1st secretary of the GA",
				name: "Tiago de Boaventura",
				img: agsec1_img,
			},
			{ role: "President of GA", name: "Gonçalo Barroso", img: agpres_img },
			{
				role: "2nd secretary of the GA",
				name: "João Batista",
				img: agsec2_img,
			},
			{ role: "Secretary of the FC", name: "Tiago Ribeiro", img: cfsec_img },
			{ role: "President of the FC", name: "Tomás Carmo", img: cfpres_img },
			{ role: "Vowel of the FC", name: "Gonçalo Carvalho", img: cfvwl_img },
		],
	},
	contact: {
		title: "TALK TO US",
		subtitle: "WE WOULD LOVE TO HEAR FROM YOU!",
		placeholder: {
			name: "First Name",
			surname: "Last Name",
			email: "E-mail",
			message: "Your message",
		},
		buttonText: "SEND MESSAGE",
	},
	footer: {
		copyright: "All rights reserved.",
		name: {
			ncgm: "Nucleus of Computer Graphics and Multimedia",
			feup: "FEUP - Faculty of Engineering of the University of Porto",
			dei: "Department of Informatics Engineering",
		},
		sitemap: {
			home: "Home",
			about: "About Us",
			team: "Our Team",
			events: "Events",
			projects: "Projects",
			contacts: "Contact us",
		},
		social_media: "Follow us on our social media, and stay up to date.",
	},
	ggj: {
		cardsTitle: "What do we offer?",
		bannerData: {
			title: "Join us in the Global Game Jam!",
			description:
				"Participa na maior game jam do mundo! Desenvolve um jogo do início ao fim em 48 horas, sozinho ou em equipa. No início da jam é dado um tema aos participantes, com base no qual estes têm de criar o seu jogo! O NCGM é anfitrião de um jam site na FEUP, onde equipas se reúnem para participar na competição! A GGJ geralmente ocorre no final de janeiro entre dia 22 e dia 28.",
			buttons: [
				{
					buttonText: "Official Site",
					buttonURL: "/",
				},
				{
					buttonText: "Sign Up",
					buttonURL: "/",
				},
			],
			img: ggjbanner,
		},
		cards: [
			{
				// food card
				title: "Food",
				description: "descriçao",
				img: appleicon,
			},
			{
				// resting card
				title: "Resting",
				description: "descriçao",
				img: sofaicon,
			},
			{
				// hygiene card
				title: "Hygiene",
				description: "descriçao",
				img: soapicon,
			},
			{
				// socializing card
				title: "Socializing",
				description: "descriçao",
				img: groupicon,
			},
		],
		panels: [
			{
				// Past Editions panel
				title: "Past Editions",
				description: [
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
				],
				img: [
					{
						img: ggjlogo,
						imgText: "GGJ Logo",
					},
				],
				imgRight: false,
				hasButton: true,
				refButton: "/projects",
				buttonText: "Previous Projects",
			},
		],
		centerpanel: [
			{
				// Location panel
				title: "Where will GGJ be in Porto?",
				description: [
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
				],
				img: [
					{
						img: ggjlocation,
						imgText: "GGJ Location",
					},
				],
				imgRight: true,
				hasButton: false,
			},
		],
	},
};

export default LOCALES_EN;
