// Libraries
import { Link } from "react-router-dom";

// Locales
import { useTranslation } from "react-i18next";

// Assets
import {
	FaFacebookSquare,
	FaLinkedin,
	FaInstagram,
	FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import logo from "../../assets/images/ncgm_logo_transparent.png";

// Styles
import "./Footer.css";
import LanguageSwitcher from "../Buttons/LanguageSwitcher";

const LinkComponent = ({ page, content }) => {
	return (
		<div className="col col-6">
			<Link className="mb-1" to={`/${page}`}>
				<div>{content}</div>
			</Link>
		</div>
	);
};

const socialMedia = [
	{
		media: "Facebook",
		link: "https://www.facebook.com/ncgmfeup/",
		icon: FaFacebookSquare,
	},
	{
		media: "Instagram",
		link: "https://www.instagram.com/ncgmfeup/",
		icon: FaInstagram,
	},
	{
		media: "Twitter",
		link: "https://x.com/ncgmfeup",
		icon: FaXTwitter,
	},
	{
		media: "LinkedIn",
		link: "https://www.linkedin.com/company/ncgmfeup/",
		icon: FaLinkedin,
	},
	{
		media: "Youtube",
		link: "https://www.youtube.com/channel/UC5zqeMTr3AY-jS3C239YTEg",
		icon: FaYoutube,
	},
];

const CustomFaIcon = ({ name }) => {
	const FaIcon = name;
	if (!FaIcon) return <p>Icon not found!</p>;

	return <FaIcon />;
};

const FooterSocials = () => {
	return (
		<div className="footer-link">
			{socialMedia.map((media) => (
				<a
					href={media.link}
					key={media.media}
					target="_blank"
					rel="noopener noreferrer"
				>
					<span className="fa-stack media-icon">
						<CustomFaIcon name={media.icon} className="fab fa-stack-1x" />
					</span>
				</a>
			))}
		</div>
	);
};

export default function Footer() {
	const { t } = useTranslation("", {
		keyPrefix: "footer",
	});
	return (
		<div className="footer-container">
			<div className="container-fluid align-items-center">
				<div className="row footer-padding">
					<div className="col col-12 col-lg-6 height-100 align-items-center d-flex px-0 mobile-spacing">
						<div className="logo-text mr-4">
							<img id="footer-logo" src={logo} alt="ncgm logo"></img>
						</div>
						<div className="footer-text logo-text">
							<p className="mb-1">{t("name.ncgm")}</p>
							<p className="mb-1">{t("name.feup")}</p>
							<p className="mb-1">{t("name.dei")}</p>
						</div>
					</div>
					<div className="col col-12 col-lg-3 height-100 align-items-top footer-text mobile-spacing">
						<div className="row w-100 sitemap">
							<div className="col col-12">
								<p className="footer-title">SITEMAP</p>
							</div>
							<LinkComponent page="" content={t("sitemap.home")} />
							<LinkComponent page="about" content={t("sitemap.about")} />
							<LinkComponent page="team" content={t("sitemap.team")} />
							<LinkComponent page="events" content={t("sitemap.events")} />
							<LinkComponent page="projects" content={t("sitemap.projects")} />
							<LinkComponent page="contacts" content={t("sitemap.contacts")} />
						</div>
					</div>
					<div className="col col-12 col-lg-3 height-100 align-items-top footer-text">
						<p className="footer-title">SOCIAL MEDIA</p>
						<p className="middle-spacing">{t("social_media")}</p>
						<FooterSocials />
					</div>
					<div className="col col-12 footer-text mb-0 line-top align-items-center justify-content-between d-flex">
						<div className="logo-text mb-0">
							<p className="mb-0">
								© {new Date().getFullYear()} NCGM. {t("copyright")}
							</p>
						</div>
						<div className="logo-text ml-auto">
							<LanguageSwitcher />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
