// Libraries
import { Routes, Route, BrowserRouter } from "react-router-dom";

// Components
import Navbar from "../Navbar/Navbar";
import Social from "../Social/Social";
import Footer from "../Footer/Footer";

// Pages
import Home from "../../Pages/Home/Home";
import About from "../../Pages/About/About";
import Team from "../../Pages/Team/Team";
import Events from "../../Pages/Events/Events";
import Projects from "../../Pages/Projects/Projects";
import Contacts from "../../Pages/Contacts/Contacts";
// import GGJ from "../../Pages/GGJ/GGJ";

// Locales
import { i18nInit } from "../../Locales/i18n";

// Styles
import "./App.css";

function App() {
	i18nInit();

	return (
		<BrowserRouter>
			<div className="d-flex flex-column min-vh-100">
				<Navbar />
				<Social />
				<div className="flex-grow-1">
					<Routes>
						<Route path="/" exact element={<Home />} />
						<Route path="/about" exact element={<About />} />
						<Route path="/team" exact element={<Team />} />
						<Route path="/events" exact element={<Events />} />
						<Route path="/projects" exact element={<Projects />} />
						<Route path="/contacts" exact element={<Contacts />} />
						{/* <Route path="/ggj" exact element={<GGJ />} /> */}
					</Routes>
				</div>
				<Footer />
			</div>
		</BrowserRouter>
	);
}

export default App;
