// Styles
import "./Panel.css";

export default function Panel({
	type,
	title,
	description,
	img,
	imgRight,
	hasButton,
	refButton,
	buttonText,
}) {
	const images = [];
	img.forEach((img, index) => {
		images.push(
			<img
				src={img.img}
				key={index.toString()}
				alt={img.imgText}
				className="panel-img"
			></img>,
		);
	});

	const text = (
		<div className="row">
			<h3 className="paneltype text-uppercase fw-bold">{type}</h3>
			<h1 className="paneltitle mb-4 text-uppercase fw-bold">{title}</h1>
			{description.map((paragraph, index) => (
				<p className="text-white" key={`desc-${index}`}>
					{paragraph}
				</p>
			))}
			{hasButton && (
				<a
					className="rounded panelbutton text-uppercase text-center align-middle mx-3 my-4 px-3 py-3"
					href={refButton}
				>
					{buttonText}
				</a>
			)}
		</div>
	);

	return (
		<div className="row w-auto h-auto px-xl-4 ms-xl-0 pb-5">
			<div
				className={
					"col-12 col-lg-6 px-3 my-4" + (imgRight ? " panel-img-right" : "")
				}
			>
				{images}
			</div>
			<div className={"col-12 col-lg-6 px-3"}>{text}</div>
		</div>
	);
}
