import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LOCALES_PT from "./pt/locales";
import LOCALES_EN from "./en/locales";

function i18nInit() {
	i18n.use(initReactI18next).init({
		resources: {
			pt: {
				translation: LOCALES_PT,
			},
			en: {
				translation: LOCALES_EN,
			},
		},
		lng: localStorage.getItem("language") || "pt",
		fallbackLng: "en",
		supportedLngs: ["pt", "en"],
	});
	localStorage.setItem("language", geti18nLang());
}

function seti18nLang(lang) {
	i18n.changeLanguage(lang);
	localStorage.setItem("language", lang);
}

function geti18nLang() {
	return i18n.language;
}

export { i18n, i18nInit, seti18nLang, geti18nLang };
