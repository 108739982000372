// Assets
// Home Page Assets
import bannerimg from "../../assets/images/page-bg/bg-ncgm-home.jpg";
import gamedevmeetportoimg from "../../assets/images/events/gdm-panel.png"; // same as gdm_panel
import feupmayhemimg from "../../assets/images/projects/feup-mayhem.png"; // home and projects
import cgraphics from "../../assets/images/cards/cgraphics.svg"; // home and about
import gamedev from "../../assets/images/cards/gamedev.svg"; // home and about
import interaction from "../../assets/images/cards/interaction.svg"; // home and about
import multimedia from "../../assets/images/cards/multimedia.svg"; // home and about
// About Page Assets
import about_bg from "../../assets/images/page-bg/bg-ncgm-about.svg";
import feup_bg from "../../assets/images/page-bg/feup-banner.fcac53a9.svg";
// Events Page Assets
import event_bg from "../../assets/images/page-bg/bg-ncgm-events.svg";
import gdm_panel from "../../assets/images/events/gdm-panel.png";
import ggj_panel from "../../assets/images/events/globalgamejam-panel.jpg";
import learningProjectsImg from "../../assets/images/projects/learning-projects-photo.jpg";
// Project Page Assets
import projectbannerimg from "../../assets/images/page-bg/bg-ncgm-projects.jpg";
import websiteimg from "../../assets/images/projects/website-NCGM.jpg";
import mootinyimg from "../../assets/images/projects/mootiny.png";
// Team Page Assets
import team_bg from "../../assets/images/page-bg/bg-ncgm-team.svg";
// import vr_img from "../../assets/images/team/anniversary2-vr.svg";
import anniv_fmq from "../../assets/images/team/anniversary2-fbmaq.svg";
// GGJ Page Assets
import ggjbanner from "../../assets/images/events/GGJ_Baner.png";
import ggjlogo from "../../assets/images/events/GGJ_RoundLogo.png";
import ggjlocation from "../../assets/images/events/GGJ_location.png";
import appleicon from "../../assets/images/cards/apple_icon.png";
import soapicon from "../../assets/images/cards/soap_icon.png";
import groupicon from "../../assets/images/cards/group_icon.png";
import sofaicon from "../../assets/images/cards/sofa_icon.png";
// Team Members
import pres_img from "../../assets/images/team/our_team/presidente.png";
import vice_img from "../../assets/images/team/our_team/vice_presidente.png";
import treas_img from "../../assets/images/team/our_team/tesoureiro.png";
import agpres_img from "../../assets/images/team/our_team/AG_presidente.png";
import agsec1_img from "../../assets/images/team/our_team/AG_secretario1.png";
import agsec2_img from "../../assets/images/team/our_team/AG_secretario2.png";
import cfpres_img from "../../assets/images/team/our_team/CF_presidente.png";
import cfsec_img from "../../assets/images/team/our_team/CF_secretario.png";
import cfvwl_img from "../../assets/images/team/our_team/CF_vogal.png";

const LOCALES_PT = {
	navbar: {
		about: "Sobre nós",
		team: "Equipa",
		events: "Eventos",
		projects: "Projetos",
		contacts: "Contactos",
	},
	home: {
		bannerData: {
			title: "NÓS SOMOS NCGM",
			img: bannerimg,
			buttons: [
				{
					buttonURL: "/about",
					buttonText: "Conhece-nos melhor!",
				},
				{
					buttonURL: "https://forms.gle/wtvRo2iEJvNcvEEf9",
					buttonText: "Junta-te a nós!",
				},
			],
		},
		cardsSectionTitle: "As nossas paixões",
		cards: [
			{
				img: cgraphics,
				title: "Computação gráfica",
				description:
					"Esta vasta e excitante área dentro da ciência da computação está em constante evolução e é a base da fotografia digital, filmes e video jogos",
			},
			{
				img: gamedev,
				title: "Desenvolvimento de Jogos",
				description:
					"Desde os mais simples platformer 2D indies aos mais complexos jogos de estratégia AAA, no coração do NCGM reside um profundo amor por Game Dev",
			},
			{
				img: interaction,
				title: "Design de Interfaces",
				description:
					"O desenvolvimento de uma ponte esteticamente agradável, limpa, e sem qualquer fricção entre o utilizador e o dispositivo é uma arte que procuramos aprender",
			},
			{
				img: multimedia,
				title: "Multimédia",
				description:
					"No NCGM, reconhecemos a importância da interação audiovisual, e da liberdade artística que esta possibilita, em qualquer tipo de aplicação",
			},
		],
		panels: [
			{
				type: "EVENTO",
				title: "GAME DEV MEET @ PORTO",
				description: [
					"O nosso evento regular de maior calibre é o Game Dev Meet @Porto, um encontro mensal de criadores amadores, profissionais ou de interessados em jogos ou experiências multimédia.",
					"Este evento tem como objetivo fomentar a interação entre criadores e admiradores, e constitui um espaço perfeito para partilhar projetos, conviver e discutir ideias.",
					"O NCGM começou a organizar este evento em maio de 2017, contando com uma média de 50 participantes por edição!",
					"A entrada é livre, e não requer inscrição prévia! Desde designers de som a designers de imagem, escritores a artistas, programadores ou apenas curiosos, qualquer um pode participar, mostrar o seu trabalho, aprender, ensinar, e mais importante ainda, divertir-se!",
				],

				img: [{ img: gamedevmeetportoimg, imgText: "Game Dev Meet no Porto" }],
				imgRight: false,

				hasButton: true,
				refButton: "/events",
				buttonText: "VER TODOS OS EVENTOS",
			},
			{
				type: "PROJETO",
				title: "FEUP MAYHEM",
				description: [
					"Um jogo para dois jogadores baseado em Gun Mayhem.",
					"O tema no geral é a FEUP, e as personagens representam diferentes cursos de engenharia da faculdade referida.",
				],

				img: [{ img: feupmayhemimg, imgText: "FEUP Mayhem" }],
				imgRight: true,

				hasButton: true,
				refButton: "/projects",
				buttonText: "VER OS PROJETOS",
			},
		],
	},
	about: {
		// WE SEEK TO EXCEL
		bannerData: {
			title: "PROCURAMOS EXCELÊNCIA",
			description:
				"Desde a sua incepção, o NCGM procurou promover a interdisciplinaridade, explorando as sinergias, convergências e articulações possíveis entre uma multitude de áreas científicas, tecnológicas, humanísticas e artísticas. Para este efeito, temos desenvolvido inúmeros projetos e organizado e promovendo eventos dentro das suas áreas de atividade, direcionadas não só para a comunidade estudantil como também para qualquer entusiasta dessas áreas.",
			img: about_bg,
			imgText: "banner test",
			buttons: [],
		},

		// OUR HISTORY

		panelData: {
			type: "A nossa história",
			title: "A juntar talentos desde 2006",
			description: [
				"O NCGM, é uma associação sem fins lucrativos, fundada em 2006 por estudantes com interesse nas áreas de Computação Gráfica, Desenvolvimento de Jogos e Experiências Digitais, e Interação Pessoa-Computador.",

				"Inicialmente chamado NeCG, em 2017 fundiu-se com o Núcleo de Estudantes do Mestrado de Multimédia, para promover o desenvolvimento de projetos com equipas multidisciplinares e com o objetivo de ampliar o impacto de ambos núcleos nas suas comunidades. Após a oficialização desta fusão, o núcleo assumiu o nome atual de Núcleo de Computação Gráfica e Multimédia, ou NCGM.",

				"Atualmente, o Núcleo é composto por cerca de 50 estudantes, sendo a maioria dos quais estudantes da Faculdade de Engenharia da Universidade do Porto, e os restantes pertencentes a outras faculdades da área do Porto.",

				"Se estás interessado nas nossas áreas de atividade, não hesites em contactar-nos. Estamos sempre à procura de pessoas motivadas que partilham a nossa paixão por tudo o que é computação gráfica e multimédia!",
			],

			img: [{ img: feup_bg, imgText: "Campus Feup" }],
			imgRight: false,
			imgText: "História",

			hasButton: true,
			refButton: "./contact",
			buttonText: "Contacta-nos!",
		},

		// EVENTS AND PROJECTS
		evTitle: "NÓS FAZEMOS EVENTOS E PROJETOS EM",

		cardsData: [
			{
				img: cgraphics,
				title: "Computação gráfica",
			},
			{
				img: gamedev,
				title: "Desenvolvimento de Jogos",
			},
			{
				img: interaction,
				title: "Design de Interfaces",
			},
			{
				img: multimedia,
				title: "Multimédia",
			},
		],
	},
	events: {
		bannerData: {
			title: "LIGAMOS TALENTO",
			description:
				"O nosso Núcleo organiza workshops e palestras com o intuito de melhorar as capacidades e aptitudes dos seus membros e associados, fornecendo meios para que estes possam desenvolver projetos e atividades de valor para si e para a comunidade em que se inserem.",
			img: event_bg,
			imgText: "texto do banner",
			buttons: [
				{
					buttonURL: "/about",
					buttonText: "Conhece-nos!",
				},
			],
		},

		gdmPanelData: {
			type: "EVENTO",
			title: "Game Dev Meet @ Porto",
			description: [
				"O nosso evento regular de maior calibre é o Game Dev Meet @Porto, um encontro mensal de criadores amadores, profissionais ou de interessados em jogos ou experiências multimédia.",
				"Este evento tem como objetivo fomentar a interação entre criadores e admiradores, e constitui um espaço perfeito para partilhar projetos, conviver e discutir ideias.",
				"O NCGM começou a organizar este evento em maio de 2017, contando com uma média de 50 participantes por edição!",
				"A entrada é livre, e não requer inscrição prévia! Desde designers de som a designers de imagem, escritores a artistas, programadores ou apenas curiosos, qualquer um pode participar, mostrar o seu trabalho, aprender, ensinar, e mais importante ainda, divertir-se!",
				"Entra no nosso servidor de discord para ficares a par de todas as novidades!",
			],

			img: [{ img: gdm_panel, imgText: "GDM" }],
			imgRight: false,

			hasButton: true,
			refButton: "https://discord.com/invite/db9zYgvpNE",
			buttonText: "Link do servidor",
		},

		ggjPanelData: {
			type: "EVENTO",
			title: "Global Game Jam",
			description: [
				"Participa na maior game jam do mundo! Desenvolve um jogo do início ao fim em 48 horas, sozinho ou em equipa. No início da jam é dado um tema aos participantes, com base no qual estes têm de criar o seu jogo!",
				"O NCGM é anfitrião de um jam site na FEUP, onde equipas se reúnem para participar na competição!",
				"A GGJ geralmente ocorre no final de Janeiro; a data precisa pode ser encontrada no site oficial da Global Game Jam.",
			],

			img: [{ img: ggj_panel, imgText: "GGJ" }],
			imgRight: true,

			hasButton: true,
			refButton: "https://globalgamejam.org/",
			buttonText: "VISITA O WEBSITE DA GGJ!",
		},
	},
	projects: {
		projectsbannerdata: {
			img: projectbannerimg,
			title: "FAZEMOS ACONTECER",
			description:
				"O nosso Núcleo organiza workshops e palestras com o intuito de melhorar as capacidades e aptitudes dos seus membros e associados, fornecendo meios para que estes possam desenvolver projetos e atividades de valor para si e para a comunidade em que se inserem.",
		},
		websitepanelData: {
			type: "PROJETO",
			title: "WEBSITE DO NCGM",
			description: [
				"O sítio acolhedor onde estamos de momento.",
				"Arranja um lugar à volta da lareira.",
			],

			img: [{ img: websiteimg, imgText: "WEBSITE DO NCGM" }],
			imgRight: true,

			hasButton: true,
			refButton: "https://github.com/ncgmfeup/website-mern",
			buttonText: "REPOSITÓRIO DE GIT",
		},
		feupmayhemData: {
			type: "PROJETO",
			title: "FEUP MAYHEM",
			description: [
				"O FEUP Mayhem é um jogo 2D platform shooter para dois jogadores inspirado primariamente por Gun Mayhem e Super Smash Bros.",
				"O jogo tem lugar durante um semestre particularmente exaustivo, no qual os estudantes lutam para defender o seu curso de Engenharia.",
				"Os jogadores controlam uma de 4 personagens, cada uma com uma história e habilidade especial baseada no curso a que esta pertence, que tem a capacidade de mudar o rumo da batalha instantaneamente, quando bem utilizada!",
				"Estes devem usar as suas habilidades, armas e perspicácia para triunfarem sobre o seu oponente, e atirá-los para fora do mapa.",
				"Trás orgulho ao teu curso, e torna-te no derradeiro Engenheiro!",
			],

			img: [{ img: feupmayhemimg, imgText: "FEUP Mayhem" }],
			imgRight: true,

			hasButton: true,
			refButton: "https://github.com/ncgmfeup/summer-project-2020",
			buttonText: "REPOSITÓRIO DE GIT",
		},
		mootinyData: {
			type: "PROJETO",
			title: "Mootiny in Barksville",
			description: [
				"Oh não, o grande mauzão está a destruir a floresta! Junta-te à Cadela Manteiga (nome temporário) e salva os animais neste épico jogo de plataforma de ação em 2D. Dá aos teus inimigos um acabamento amanteigado enquanto desvendas os segredos deste jogo inacabado.",
				"Ideias e mecânicas foram inspiradas por jogos como Celeste, Pizza Tower, Warioland, Undertale e One Shot.",
			],

			img: [{ img: mootinyimg, imgText: "Mootiny" }],
			imgRight: false,

			hasButton: false,
		},
		learningProjectsData: {
			type: "PROJETO",
			title: "Learning Projects",
			description: [
				"Os Learning Projects são uma excelente oportunidade para quem tem interesse na área de desenvolvimento de jogos e deseja dar os primeiros passos na aprendizagem de motores e outras ferramentas de desenvolvimento de jogos muito utilizadas na indústria.",
				"Durante a realização destes projetos, cada equipa, composta por dois a três membros, tem o apoio de mentores experientes que os guiam durante o processo de desenho e desenvolvimento dos seus jogos, garantido que a equipa completa com sucesso o projeto em dois meses.",
			],

			img: [{ img: learningProjectsImg, imgText: "Learning Project" }],
			imgRight: false,
		},
	},
	team: {
		bannerData: {
			img: team_bg,
			title: "SOMOS UMA EQUIPA DINÂMICA",
			description:
				"Nós priorizamos uma abordagem prática no que toca ao desenvolvimento de novas aptitudes, adquirindo experiência e aprendendo ao fazer, o que resulta não só em mais interesse por parte dos nossos membros, como também promove a sua creatividade a habilidade de resolução de problemas.",
		},
		teamPanelData: {
			type: "NÓS SOMOS",
			title: "60+ MEMBROS",
			description: [
				"Com uma história relativamente recente, que começou em 2006, o NCGM tem tentado captar e dar uma plataforma útil a todos os entusiastas interessados na área da Computação Gráfica, Game Dev e Multimédia.",
				"Procuramos fomentar uma forte relação de entreajuda e partilha de conhecimento entre os nossos membros. De estudante para estudante, ou até mesmo a partir dos nossos alumni, empenhamo-nos para que se crie um melhor processo de adaptação dos mais novos a um mundo que, para muitos, pode ser bastante desafiante (ou até completamente desconhecido).",
				"Queres ser o nosso próximo membro? Inscreve-te já",
			],

			img: [{ img: anniv_fmq, imgText: "evento público com audiência" }],
			imgRight: false,

			hasButton: true,
			refButton: "./contact",
			buttonText: "JUNTA-TE A NÓS!",
		},
		ourTeam: "A Nossa Equipa",
		boardNCGM: [
			{ role: "Vice-Presidente", name: "António Matos", img: vice_img },
			{ role: "Presidente", name: "Ana Ramos", img: pres_img },
			{ role: "Tesoureiro", name: "Tomás Moreira", img: treas_img },
			{
				role: "1ª Secretárip da MAG",
				name: "Tiago de Boaventura",
				img: agsec1_img,
			},
			{ role: "Presidente da MAG", name: "Gonçalo Barroso", img: agpres_img },
			{ role: "2º Secretário da MAG", name: "João Batista", img: agsec2_img },
			{ role: "Secretário do CF", name: "Tiago Ribeiro", img: cfsec_img },
			{ role: "Presidente do CF", name: "Tomás Carmo", img: cfpres_img },
			{ role: "Vogal do CF", name: "Gonçalo Carvalho", img: cfvwl_img },
		],
	},
	contact: {
		title: "FALA CONNOSCO",
		subtitle: "ESTAMOS DESEJOSOS DE TE CONHECER!",
		placeholder: {
			name: "Primeiro Nome",
			surname: "Último Nome",
			email: "E-mail",
			message: "A tua mensagem",
		},
		buttonText: "ENVIAR MENSAGEM",
	},
	footer: {
		copyright: "Todos os direitos reservados.",
		name: {
			ncgm: "Núcleo de Computação Gráfica e Multimédia",
			feup: "FEUP - Faculdade de Engenharia da Universidade do Porto",
			dei: "Departamento de Engenharia Informática",
		},
		sitemap: {
			home: "Início",
			about: "Sobre nós",
			team: "Equipa",
			events: "Eventos",
			projects: "Projetos",
			contacts: "Contactos",
		},
		social_media: "Segue-nos nas redes sociais e fica a par de tudo.",
	},
	ggj: {
		cardsTitle: "O que oferecemos?",
		bannerData: {
			title: "Junta-te a nós na Global Game Jam",
			description:
				"Participa na maior game jam do mundo! Desenvolve um jogo do início ao fim em 48 horas, sozinho ou em equipa. No início da jam é dado um tema aos participantes, com base no qual estes têm de criar o seu jogo! O NCGM é anfitrião de um jam site na FEUP, onde equipas se reúnem para participar na competição! A GGJ geralmente ocorre no final de janeiro entre dia 22 e dia 28.",
			buttons: [
				{
					buttonText: "Site Oficial",
					buttonURL: "/",
				},
				{
					buttonText: "Inscreve-te",
					buttonURL: "/",
				},
			],
			img: ggjbanner,
		},
		cards: [
			{
				// food card
				title: "Alimentação",
				description: "descriçao",
				img: appleicon,
			},
			{
				// resting card
				title: "Descanso",
				description: "descriçao",
				img: sofaicon,
			},
			{
				// hygiene card
				title: "Higiene",
				description: "descriçao",
				img: soapicon,
			},
			{
				// socializing card
				title: "Convívio",
				description: "descriçao",
				img: groupicon,
			},
		],
		panels: [
			{
				// Past Editions panel
				title: "Edições anteriores",
				description: [
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
				],
				img: [
					{
						img: ggjlogo,
						imgText: "GGJ Logo",
					},
				],
				imgRight: false,
				hasButton: true,
				refButton: "/projects",
				buttonText: "Projetos Anteriores",
			},
		],
		centerpanel: [
			{
				// Location panel
				title: "Onde será o site da GGJ do NCGM?",
				description: [
					"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
				],
				img: [
					{
						img: ggjlocation,
						imgText: "GGJ Location",
					},
				],
				imgRight: true,
				hasButton: false,
			},
		],
	},
};

export default LOCALES_PT;
