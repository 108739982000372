// Components
import Banner from "../../Components/Banner/Banner";
import Panel from "../../Components/Panel/Panel";

// Locales
import { useTranslation } from "react-i18next";

export default function Events() {
	const { t } = useTranslation("", {
		keyPrefix: "events",
	});

	return (
		<div>
			<Banner {...t("bannerData", { returnObjects: true })} />
			<div className="d-flex flex-column align-items-center container py-5">
				<Panel {...t("gdmPanelData", { returnObjects: true })} />
				<Panel {...t("ggjPanelData", { returnObjects: true })} />
			</div>
		</div>
	);
}
