// Libraries
import React from "react";

// Assets
import { FaAngleDown, FaLongArrowAltRight } from "react-icons/fa";

// Styles
import "./Banner.css";

export default function BackgroundBanner({
	title,
	description,
	buttons,
	img,
	imgText,
}) {
	const getButton = (buttonData, index) => {
		if (buttonData.buttonURL && buttonData.buttonAction)
			console.warn(
				"Both buttonURL and buttonAction were provided for button with text " +
					buttonData.buttonText +
					", but only one can be used!",
			);

		// first button is red, the rest are yellow
		const buttonclasses =
			"rounded btn py-3 px-4" +
			(index === 0
				? " accent-transparent-button-red"
				: " accent-transparent-button-yellow");
		// if an url was passed, we want a button that links to another website
		if (buttonData.buttonURL) {
			return (
				<a href={buttonData.buttonURL} className={buttonclasses}>
					{buttonData.buttonText}{" "}
					<FaLongArrowAltRight size="20" className="ms-3" />
				</a>
			);
		}
		// if an action was provided, we want a button that runs a function on click
		else if (buttonData.buttonAction) {
			return (
				<button onClick={buttonData.buttonAction} className={buttonclasses}>
					{buttonData.buttonText}
				</button>
			);
		}
		console.warn(
			"Text provided for button, but no action or url was given - " +
				buttonData.buttonText,
		);
		return <></>;
	};

	return (
		<div className="container-fluid vh-100 position-relative overflow-hidden">
			<div className="h-100 row full-page-minus-nav-footer-height d-flex flex-column-xs flex-row-lg justify-content-center align-items-center position-relative">
				<div className="navbar-margin mt-lg-0 full-page-padding-xs full-page-padding-left-lg pe-lg-0 pt-5 py-lg-0 col-lg-6 col-12 z-5">
					<h1 className="banner-title">{title}</h1>
					{description && <p className="banner-description">{description}</p>}
					{/* If there's a button (text exists)) */}
					<div>
						{buttons && (
							<div className="d-flex flex-column-xs flex-row-lg pb-4 pb-xl-0">
								{buttons.map((button, index) => (
									<div
										key={index.toString()}
										className="nowrap pb-2 pb-lg-0 pe-0 pe-lg-4"
									>
										{getButton(button, index)}
									</div>
								))}
							</div>
						)}
					</div>
				</div>
				<div className="banner-image-container d-flex col-lg-6 justify-content-center align-items-center">
					<img className="banner-image" src={img} alt={imgText}></img>
				</div>
				<div className="position-absolute bottom-0 d-flex w-100 justify-content-center pb-2">
					<button className="btn banner-arrow" onClick={clickArrow}>
						<FaAngleDown className="icon-style" size="20" />
					</button>
				</div>
			</div>
		</div>
	);
}

function clickArrow() {
	const vh = window.innerHeight;
	const nb = 100;
	window.scrollTo({
		top: vh - nb,
		behavior: "smooth",
	});
}
