// Components
import Banner from "../../Components/Banner/Banner";
import Panel from "../../Components/Panel/Panel";

// Locales
import { useTranslation } from "react-i18next";

//Styles
import "./Team.css";

//OUR TEAM

export default function Team() {
	const { t } = useTranslation("", {
		keyPrefix: "team",
	});

	return (
		<div>
			<Banner {...t("bannerData", { returnObjects: true })} />
			<div className="d-flex flex-column align-items-center container py-5">
				<Panel {...t("teamPanelData", { returnObjects: true })} />

				<div className="row">
					<h2 className="cards-title text-white fw-bold text-uppercase text-center mb-5">
						{t("ourTeam", { returnObjects: true })}
					</h2>
				</div>
				<ul
					id="team-list"
					className="row px-0 px-xl-2 mx-0 mx-xl-2 d-flex justify-content-center"
				>
					{t("boardNCGM", { returnObjects: true }).map((team, i) => (
						<div
							key={`team-${i}`}
							className="col-12 col-sm-6 col-lg-4 d-flex justify-content-center align-items-center py-2 px-2"
						>
							<li className="text-center team-li position-relative">
								<img className="img-fluid" src={team.img}></img>
								<div className="w-100 position-absolute bottom-0 team-li-caption">
									<h4>{team.name}</h4>
									<h5>{team.role}</h5>
								</div>
							</li>
						</div>
					))}
				</ul>
			</div>
		</div>
	);
}
