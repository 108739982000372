// Assets
import threedots from "../../assets/images/cards/3dots.svg";

export default function Card({ data }) {
	return (
		<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 px-3 py-3">
			<div className="d-flex justify-content-center px-3">
				<img src={data.img} alt={data.title}></img>
			</div>
			<div className="flex-row px-3">
				<h3
					className={`mt-3 text-white fw-bold text-uppercase title-rem ${
						data.description === undefined &&
						"text-center justify-content-center"
					}`}
				>
					{data.title}
				</h3>
				{data.description !== undefined && (
					<>
						<p className="mb-0">{data.description}</p>
						<img src={threedots} alt="card bottom"></img>
					</>
				)}
			</div>
		</div>
	);
}
