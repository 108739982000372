// Locales
import { seti18nLang, geti18nLang } from "../../Locales/i18n.js";
import { useEffect, useState } from "react";

// Styles
import "./LanguageSwitcher.css";

//   simple button that changes the language being used on the webpage on click
//   missing styling and whatnot
export default function LanguageSwitcher() {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [currentLang, setCurrentLang] = useState(
		geti18nLang() === "en" ? "English" : "Português",
	);

	useEffect(() => {
		seti18nLang(currentLang === "English" ? "en" : "pt");
	}, [currentLang]);

	const changeLanguage = (lang) => {
		if (lang === currentLang) {
			return;
		}
		setCurrentLang(lang);
		setDropdownOpen(false);
	};

	return (
		<div className={`container btn-group ${dropdownOpen ? "" : "dropup"}`}>
			<button
				className="btn accent-transparent-button-red dropdown-toggle"
				type="button"
				aria-expanded={dropdownOpen}
				onClick={() => setDropdownOpen(!dropdownOpen)}
			>
				{currentLang}
			</button>
			{dropdownOpen && (
				<ul className={"dropdown-menu dropdown-menu-dark menu-dropup show"}>
					<li>
						<button
							className="dropdown-item"
							onClick={() => changeLanguage("English")}
						>
							English
						</button>
					</li>
					<li>
						<button
							className="dropdown-item"
							onClick={() => changeLanguage("Português")}
						>
							Português
						</button>
					</li>
				</ul>
			)}
		</div>
	);
}
