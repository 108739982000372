// Assets
import {
	FaFacebookSquare,
	FaInstagram,
	FaLinkedin,
	FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
// Styles
import "./Social.css";

const socialMedia = [
	{
		media: "Facebook",
		link: "https://www.facebook.com/ncgmfeup/",
		icon: FaFacebookSquare,
	},
	{
		media: "Instagram",
		link: "https://www.instagram.com/ncgmfeup/",
		icon: FaInstagram,
	},
	{
		media: "Twitter",
		link: "https://x.com/ncgmfeup",
		icon: FaXTwitter,
	},
	{
		media: "LinkedIn",
		link: "https://www.linkedin.com/company/ncgmfeup/",
		icon: FaLinkedin,
	},
	{
		media: "Youtube",
		link: "https://www.youtube.com/channel/UC5zqeMTr3AY-jS3C239YTEg",
		icon: FaYoutube,
	},
];

const CustomFaIcon = ({ name }) => {
	const FaIcon = name;
	if (!FaIcon) return <p>Icon not found!</p>;

	return <FaIcon />;
};

export default function Social() {
	return (
		<div id="sticky-social" className="z-10">
			<ul>
				{socialMedia.map((media) => (
					<li key={media.media}>
						<a href={media.link} target="_blank" rel="noopener noreferrer">
							<span className="media-icon">
								<CustomFaIcon name={media.icon} />
							</span>
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}
